import {ApiModel} from "../../../common/api/model/ApiModel";

export class Vendor extends ApiModel {
    id: number = 0;
    name: string = "";
    purposes: number[] = [];
    legIntPurposes: number[] = [];
    extraPurposes: number[] = [];
    legIntExtraPurposes: number[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
