import React from "react";
import {SegmentFilterType} from "../../../../api/model/storage-filter/segment/SegmentFilterType";

export enum ModalProcessCountingActionType {
    UPDATE_SEGMENT_FILTER_TYPE = "UPDATE_SEGMENT_FILTER_TYPE"
}

type ProcessCountingState = {
    segmentFilterType: string;
}

type ModalProcessCountingContext = {
    processCounting: ProcessCountingState;
    setProcessCounting: React.Dispatch<ProcessCountingAction>;
}

type ProcessCountingAction = {
    type: ModalProcessCountingActionType.UPDATE_SEGMENT_FILTER_TYPE;
    segmentFilterType: string;
}

const initialState: ProcessCountingState = {
    segmentFilterType: SegmentFilterType.SEGMENT.name
};

function modalProcessCountingReducer(state: ProcessCountingState, action: ProcessCountingAction): ProcessCountingState {
    switch (action.type) {
        case ModalProcessCountingActionType.UPDATE_SEGMENT_FILTER_TYPE: {
            return {...state, segmentFilterType: action.segmentFilterType};
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const modalProcessCountingContext = React.createContext<ModalProcessCountingContext | undefined>(undefined);

function ModalProcessCountingProvider({children}: { children: React.ReactNode }) {
    const [processCounting, setProcessCounting] = React.useReducer(modalProcessCountingReducer, initialState);

    return (
        <modalProcessCountingContext.Provider value={{processCounting, setProcessCounting}}>
            {children}
        </modalProcessCountingContext.Provider>
    );
}

function useModalProcessCounting() {
    const context = React.useContext(modalProcessCountingContext);
    if (context === undefined) {
        throw new Error("useModalProcessCounting must be used within a ModalProcessCountingProvider");
    }
    return context;
}

export {ModalProcessCountingProvider, useModalProcessCounting};
