import {ApiModel} from "../../../../common/api/model/ApiModel";
import {VendorListName} from "../../vendor/VendorListName";

export class StorageConsentFilter extends ApiModel {
    purposes: number[] = [];
    purposes_li: number[] = [];
    extra_purposes: number[] = [];
    extra_purposes_li: number[] = [];
    vendor_id: number = 0;
    vendor_list: VendorListName = VendorListName.SIRDATA;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            purposes: this.purposes,
            purposes_li: this.purposes_li,
            extra_purposes: this.extra_purposes,
            extra_purposes_li: this.extra_purposes_li,
            vendor_id: this.vendor_id,
            vendor_list: this.vendor_list
        };
    }
}
