import {ApiModel} from "../../../common/api/model/ApiModel";
import {VendorListName} from "./VendorListName";
import {Vendor} from "./Vendor";

export class VendorList extends ApiModel {
    name: VendorListName = VendorListName.SIRDATA;
    vendors: Vendor[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObjects("vendors", Vendor);
    }
}
